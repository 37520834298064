import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Hero from "../components/Hero";
import SpacingVertical from "../components/SpacingVertical";
import WhoWeAre from "../components/WhoWeAre";
import OurServices from "../components/OurServices";
import CallToAction from "../components/CallToAction";
import Strings from "../constants/Strings";
import Theme from "../constants/Theme";
import Colors from "../constants/Colors";
import Tools from "../components/Tools";
import Navigation from "../components/Navigation";
import HeroContent from "../components/HeroContent";
import FromOurBlog from "../components/FromOurBlog";
import Capabilities from "../components/Capabilities";

const IndexPage = () => {
  return (
    <Layout showNav={false}>
      <SEO title={Strings.Home.title} />
      <Navigation theme={Theme.light} />
      <Hero>
        <HeroContent />
      </Hero>
      <OurServices />
      <Tools />
      <Capabilities />
      <div style={styles.whoWeAreContainer}>
        <WhoWeAre />
        <SpacingVertical rems={4} />
      </div>
      <FromOurBlog />
      <CallToAction
        backgroundColor={Colors.grayLight}
        inputColor={Colors.white}
        labelColor={Colors.blueDark}
      />
    </Layout>
  );
};

const styles = {
  whoWeAreContainer: {
    backgroundColor: Colors.grayLight,
  },
};

export default IndexPage;
