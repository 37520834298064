import React from "react";
import "./Layout.css";
import ToolsImage from "../images/tools/toolsLogos.png";
import Colors from "../constants/Colors";
import Strings from "../constants/Strings";
import SpacingVertical from "./SpacingVertical";

const Tools = () => {
  return (
    <div className="marginStandard" style={styles.container}>
      <SpacingVertical rems={4} />
      <p style={styles.text}>{Strings.Tools.title.toUpperCase()}</p>
      <SpacingVertical rems={1} />
      <img style={styles.image} src={ToolsImage} alt="Machine Learning tools" />
      <SpacingVertical rems={4} />
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  } as const,
  text: {
    color: Colors.grayDark,
    fontSize: "0.75rem",
    fontWeight: 600,
    letterSpacing: "1px",
  },
  image: {
    flex: 1,
    maxHeight: "60px",
    objectFit: "contain",
  } as const,
};

export default Tools;
