import React from "react";
import UseBlogList from "../hooks/UseBlogList";
import SpacingVertical from "./SpacingVertical";
import Strings from "../constants/Strings";
import PostList from "./PostList";

const FromOurBlog = () => {
  const allMarkdownRemark = UseBlogList();
  return (
    <div className="marginStandard">
      <div className="marginFocus">
        <SpacingVertical rems={4} />
        <h2 style={styles.textCenter}>{Strings.Blog.description}</h2>
        <PostList posts={allMarkdownRemark.edges} />
        <SpacingVertical rems={3} />
      </div>
    </div>
  );
};

const styles = {
  textCenter: {
    textAlign: "center",
  } as const,
};

export default FromOurBlog;
