import React from "react";
import Colors from "../constants/Colors";
import SpacingVertical from "./SpacingVertical";
import "./Shadow.css";
import LinkArrow from "./LinkArrow";

interface CardProps {
  className: string;
  title: string;
  message: string;
  icon: JSX.Element;
  to: string;
  toText: string;
}

const Card = (props: CardProps) => {
  const { className, title, message, icon, to, toText } = props;
  const computedClassName = `${className} shadow`;
  return (
    <div className={computedClassName} style={styles.card}>
      <div style={styles.iconBackground}>{icon}</div>
      <SpacingVertical rems={2} />
      <h3 style={styles.textCenter}>{title}</h3>
      <p style={styles.textCenter}>{message}</p>
      <SpacingVertical rems={2} />
      <LinkArrow to={to}>{toText}</LinkArrow>
    </div>
  );
};

const styles = {
  card: {
    backgroundColor: Colors.white,
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    paddingTop: "4rem",
    paddingBottom: "4rem",
  } as const,
  iconBackground: {
    backgroundColor: Colors.blueDark,
    paddingLeft: "0.75rem",
    paddingRight: "0.75rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.25rem",
  },
  textCenter: {
    textAlign: "center",
  } as const,
};

export default Card;
