import React from "react";
import { Link } from "gatsby";
import SpacingHorizontal from "./SpacingHorizontal";
import Arrow from "./Arrow";
import "./Link.css";

interface LinkArrowProps {
  to: string;
  children: string;
}

const LinkArrow = (props: LinkArrowProps) => {
  const { to, children } = props;
  return (
    <Link className="link" to={to} style={styles.buttonLink}>
      {children.toUpperCase()}
      <SpacingHorizontal rems={0.5} />
      <Arrow />
    </Link>
  );
};

const styles = {
  buttonLink: {
    textAlign: "left",
    fontWeight: 500,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "1rem",
  } as const,
};

export default LinkArrow;
