import React from "react";
import Strings from "../constants/Strings";
import Colors from "../constants/Colors";

const Tagline = () => {
  return (
    <div style={styles.focusZone}>
      <h1 style={styles.textTitle}>{Strings.Home.title}</h1>
      <p style={styles.textSubtitle}>{Strings.Home.subtitle}</p>
    </div>
  );
};

const styles = {
  focusZone: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  textTitle: {
    textAlign: "left",
    color: Colors.blueDark,
  },
  textSubtitle: {
    flexGrow: 1,
    textAlign: "left",
    color: Colors.blueDark,
    fontSize: "1.6rem",
    fontWeight: 300,
    lineHeight: 1.18,
  },
};

export default Tagline;
