import React from "react";
import { Link } from "gatsby";
import SpacingHorizontal from "./SpacingHorizontal";
import SpacingVertical from "./SpacingVertical";
import WhoWeAreText from "./WhoWeAreText";
import Strings from "../constants/Strings";
import ZoomImage from "../images/team-video-chat.png";
import "./HideResponsive.css";
import "./Layout.css";
import Endpoint from "../constants/Endpoint";
import LinkArrow from "./LinkArrow";

const WhoWeAre = () => {
  return (
    <div>
      <SpacingVertical rems={4} />
      <div className="marginStandard" style={styles.contentContainer}>
        <div style={styles.images}>
          <Link to={Endpoint.demos}>
            <img src={ZoomImage} alt="SharpML team photo" />
          </Link>
        </div>
        <SpacingHorizontal className="hideMobile" />
        <SpacingHorizontal className="hideMobile" />
        <SpacingHorizontal className="hideMobile" />
        <SpacingHorizontal className="hideMobile" />
        <div style={styles.text}>
          <h2>{Strings.WhoWeAre.title}</h2>
          <WhoWeAreText />
          <LinkArrow to={Endpoint.demos}>{Strings.Models.try}</LinkArrow>
          <SpacingVertical className="hideDesktop" rems={2} />
        </div>
      </div>
    </div>
  );
};

const styles = {
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "start",
  } as const,
  images: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    flexBasis: "20em",
    justifyContent: "left",
  } as const,
  text: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    flexBasis: "20em",
  } as const,
  textTitle: {
    textAlign: "center",
  } as const,
};

export default WhoWeAre;
