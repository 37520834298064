import { useStaticQuery, graphql } from "gatsby";

const UseBlogList = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { type: { eq: "blog" } } }
          sort: { order: DESC, fields: frontmatter___date }
          limit: 3
        ) {
          edges {
            node {
              frontmatter {
                title
                date
              }
              fields {
                slug
              }
              excerpt(pruneLength: 140)
            }
          }
        }
      }
    `
  );
  return allMarkdownRemark;
};

export default UseBlogList;
