import React from "react";

interface SVGProps {
  color: string;
}

const CloudIcon = (props: SVGProps) => {
  const { color } = props;
  return (
    <div style={{ padding: 4 }}>
      <svg
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        height="24"
        width="24"
        stroke={color}
      >
        <path d="M8 17a5 5 0 01-.916-9.916 5.002 5.002 0 019.832 0A5.002 5.002 0 0116 17m-7-5l3-3m0 0l3 3m-3-3v12"></path>
      </svg>
    </div>
  );
};

export default CloudIcon;
