import React from "react";
import SpacingVertical from "../components/SpacingVertical";
import "./Layout.css";

const Hero = (props) => (
  <div className="marginStandard">
    <SpacingVertical rems={4} />
    {props.children}
    <SpacingVertical rems={4} />
  </div>
);

export default Hero;
