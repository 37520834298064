import React from "react";
import PostCard from "../components/PostCard";
import "./Layout.css";

const PostList = (props) => {
  const { posts } = props;

  return (
    <div style={styles.container}>
      {posts.map(({ node }, index) => (
        <PostCard node={node} index={index} key={index} />
      ))}
    </div>
  );
};

const styles = {
  container: {
    paddingBottom: "2.0rem",
  },
  titleText: {
    marginBottom: "2.0rem",
  },
};

export default PostList;
