import React from "react";

interface SVGProps {
  color: string;
}

const DataIcon = (props: SVGProps) => {
  const { color } = props;
  return (
    <div style={{ padding: 4 }}>
      <svg
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        height="24"
        width="24"
        stroke={color}
      >
        <path d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"></path>
      </svg>
    </div>
  );
};

export default DataIcon;
