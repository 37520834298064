import React from "react";
import { Link } from "gatsby";
import Strings from "../constants/Strings";
import SpacingVertical from "../components/SpacingVertical";
import "./Link.css";

const PostCard = (props) => {
  const { node, index } = props;
  const { frontmatter, fields, excerpt } = node;
  const { title, date } = frontmatter;

  return (
    <div style={styles.image}>
      <Link className="link" to={fields.slug} key={index}>
        <div>
          <h6 style={styles.dateText}>{date.toUpperCase()}</h6>
          <h2 className="link" style={styles.titleText}>
            {title}
          </h2>
        </div>
        <div className="link" style={styles.snippetText}>
          {excerpt}
          {Strings.Snippet.ellipses}
          <b>{Strings.Snippet.title}</b>
        </div>
        <SpacingVertical rems={1} />
      </Link>
    </div>
  );
};

const styles = {
  image: {
    minWidth: "30%",
  },
  dateText: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  snippetText: {
    marginBottom: "10px",
  },
  titleText: {
    marginBlockEnd: "0.6rem",
  },
};

export default PostCard;
