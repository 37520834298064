import React from "react";
import { Link } from "gatsby";
import FormEmail from "../components/FormEmail";
import Strings from "../constants/Strings";
import Colors from "../constants/Colors";
import Endpoint from "../constants/Endpoint";
import Tagline from "../components/Tagline";
import SpacingHorizontal from "../components/SpacingHorizontal";
import HeroImage from "../images/construction.png";

const HeroContent = () => {
  return (
    <div style={styles.contentContainer}>
      <div style={styles.text}>
        <Tagline />
        <div>
          <FormEmail
            labelColor={Colors.blueDark}
            inputColor={Colors.grayLight}
            buttonTitle={Strings.FormEmail.button}
          />
        </div>
      </div>
      <SpacingHorizontal className="hideMobile" />
      <SpacingHorizontal className="hideMobile" />
      <SpacingHorizontal className="hideMobile" />
      <SpacingHorizontal className="hideMobile" />
      <div style={styles.images}>
        <Link to={Endpoint.demos}>
          <img src={HeroImage} alt="SharpML Vision API demos" />
        </Link>
      </div>
    </div>
  );
};

const styles = {
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "start",
  } as const,
  images: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    flexBasis: "20em",
    justifyContent: "left",
  } as const,
  text: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    flexBasis: "20em",
    justifyContent: "center",
  } as const,
};

export default HeroContent;
