import React from "react";

interface SVGProps {
  color: string;
}

const CameraIcon = (props: SVGProps) => {
  const { color } = props;
  return (
    <div style={{ padding: 4 }}>
      <svg
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        height="24"
        width="24"
        stroke={color}
      >
        <path d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"></path>
        <path d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"></path>
      </svg>
    </div>
  );
};

export default CameraIcon;
