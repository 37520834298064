import React from "react";
import SpacingVertical from "./SpacingVertical";
import SpacingHorizontal from "./SpacingHorizontal";
import Card from "./Card";
import CameraIcon from "./CameraIcon";
import DataIcon from "./DataIcon";
import CloudIcon from "./CloudIcon";
import Colors from "../constants/Colors";
import Strings from "../constants/Strings";
import Endpoint from "../constants/Endpoint";
import "../components/Layout.css";
import "../components/ColumnResponsive.css";

const OurServices = () => {
  return (
    <div className="marginStandard" style={styles.container}>
      <SpacingVertical rems={4} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2 style={styles.centerText}>{Strings.OurServices.title}</h2>
        <p style={styles.centerText}>{Strings.OurServices.description}</p>
      </div>
      <SpacingVertical />
      <div className="container">
        <Card
          className="section"
          title={Strings.OurServices.Quick.title}
          message={Strings.OurServices.Quick.message}
          icon={<DataIcon color={Colors.white} />}
          to="/contact"
          toText="Schedule a chat"
        />
        <SpacingHorizontal rems={2} />
        <SpacingVertical />
        <Card
          className="section"
          title={Strings.OurServices.Flexible.title}
          message={Strings.OurServices.Flexible.message}
          icon={<CameraIcon color={Colors.white} />}
          to={Endpoint.demos}
          toText={Strings.Models.try}
        />
        <SpacingHorizontal rems={2} />
        <SpacingVertical />
        <Card
          className="section"
          title={Strings.OurServices.Customizable.title}
          message={Strings.OurServices.Customizable.message}
          icon={<CloudIcon color={Colors.white} />}
          to="/contact"
          toText="Schedule a chat"
        />
      </div>
      <SpacingVertical rems={4} />
    </div>
  );
};

const styles = {
  centerText: { textAlign: "center", maxWidth: "600px" } as const,
  container: {
    backgroundColor: Colors.grayLight,
  },
  cards: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
  },
};

export default OurServices;
