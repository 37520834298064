import React from "react";
import { Link } from "gatsby";
import Strings from "../constants/Strings";
import Endpoint from "../constants/Endpoint";
import SpacingHorizontal from "../components/SpacingHorizontal";
import SpacingVertical from "../components/SpacingVertical";
import AerialImage from "../images/aerial.png";
import LinkArrow from "./LinkArrow";

const Capabilities = () => {
  return (
    <div>
      <div className="marginStandard" style={styles.contentContainer}>
        <div style={styles.text}>
          <h2>{Strings.Capabilities.title}</h2>
          <ul>
            <li>{Strings.Capabilities.dataCollection}</li>
            <li>{Strings.Capabilities.classification}</li>
            <li>{Strings.Capabilities.detection}</li>
            <li>{Strings.Capabilities.tracking}</li>
            <li>{Strings.Capabilities.counting}</li>
            <li>{Strings.Capabilities.ocr}</li>
            <li>{Strings.Capabilities.productization}</li>
          </ul>
          <LinkArrow to={Endpoint.demos}>{Strings.Models.try}</LinkArrow>
          <SpacingVertical className="hideDesktop" rems={2} />
        </div>
        <SpacingHorizontal className="hideMobile" />
        <SpacingHorizontal className="hideMobile" />
        <SpacingHorizontal className="hideMobile" />
        <SpacingHorizontal className="hideMobile" />
        <div style={styles.images}>
          <Link to={Endpoint.demos}>
            <img src={AerialImage} alt="SharpML capabilities, link to demo" />
          </Link>
        </div>
      </div>
      <SpacingVertical rems={4} />
    </div>
  );
};

const styles = {
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "start",
  } as const,
  images: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    flexBasis: "20em",
    justifyContent: "left",
  } as const,
  text: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    flexBasis: "20em",
    justifyContent: "center",
  } as const,
};

export default Capabilities;
