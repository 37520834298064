import React from "react";
import Strings from "../constants/Strings";

const WhoWeAreText = () => {
  return (
    <p>
      {Strings.WhoWeAre.subtitle}
      <br />
      <br />
      {Strings.WhoWeAre.value}
    </p>
  );
};

export default WhoWeAreText;
