import React from "react";
import SpacingProps from "../types/SpacingProps";

const SpacingHorizontal = (props: SpacingProps) => {
  const { className, rems } = props;
  const unwrappedRems = rems || 1.0;
  const computedWidth = {
    width: `${unwrappedRems}rem`,
  };
  return <div className={className} style={computedWidth} />;
};

export default SpacingHorizontal;
